export const instagram = async (parentElement, url) => {
    if (!parentElement)
        return;
    await axios
        .get(url)
        .then((res) => {
        const res_data = res.data.media.data;
        console.log(res_data);
        let appendElement = '';
        for (let i = 0; i < res_data.length; i++) {
            appendElement += `
        <li>
          <a href=${res_data[i].permalink} target="_blank">
          <img src="${res_data[i].media_url}" alt="${res_data[i].caption}">
          </a>
        </li>`;
        }
        parentElement.innerHTML = appendElement;
    })
        .catch((error) => {
        parentElement.innerHTML = '<p>エラーが発生しました</p>';
        new Error(error.message);
    });
};
