import '@babel/polyfill';
import { matchHeight } from './matchHeigth';
import { instagram } from './instagram';
jQuery(function ($) {
    console.log('gens.jsが読みこまれた');
    $('body').addClass('jquery-add-class');
});
const instagramParent = document.querySelector('#instagram-list');
instagram(instagramParent, 'https://graph.facebook.com/v15.0/17841456394087487?fields=name%2Cmedia.limit(12)%7Bcaption%2Cmedia_url%2Cthumbnail_url%2Cpermalink%7D&access_token=EAAIvSjlkeTkBAF3nAs5JEwrfPSojBnQcN1X7BoD3b91X9s1NmPWg3RzMCNqNhdY00CjRk1kL1a7ZAes8CTXNqlT3uuOQNdG1bFz7THgErivmztfOpipnuRR2GNI5nO1MoTzi51XJhZBZCeB09zXDkYBdidRu6ovKjL4ZA6qrZA53OAe0A1AVNTRIZC8k4CpQcZD');
if (document.querySelector('.lifetime-swiper')) {
    const swiperLifetimeRight = new Swiper('.lifetime-swiper', {
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        speed: 2000
    });
}
const swiper = new Swiper('.kv-swiper', {
    effect: 'fade',
    centeredSlides: true,
    speed: 1000,
    autoplay: {
        delay: 1500,
        disableOnInteraction: false
    }
});
const futureMatch = document.querySelector('#future-match');
const futureAlign = document.querySelectorAll('#future-align');
window.addEventListener('load', () => {
    matchHeight(futureMatch, futureAlign);
});
window.addEventListener('resize', () => {
    matchHeight(futureMatch, futureAlign);
});
const grouthHeight = document.querySelector(`#grouth-match`);
const grouthAlign = document.querySelectorAll(`#grouth-align`);
window.addEventListener(`load`, () => {
    matchHeight(grouthHeight, grouthAlign);
});
window.addEventListener(`resize`, () => {
    matchHeight(grouthHeight, grouthAlign);
});
const strengthHeight = document.querySelector(`#strength-match`);
const strengthAlign = document.querySelectorAll(`#strength-align`);
window.addEventListener(`load`, () => {
    matchHeight(strengthHeight, strengthAlign);
});
window.addEventListener(`resize`, () => {
    matchHeight(strengthHeight, strengthAlign);
});
const addToReiIframe = `<iframe id="addToReiIframe" src="https://www.youtube.com/embed/dnb7MrIPdlA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
const youtubeBox = document.querySelector('#youtubeTo-Rei');
const ToReiVideoPause = () => {
    const video = document.querySelector('#js-to-rei-video');
    video.pause();
};
const addYoutube = () => {
    youtubeBox.insertAdjacentHTML('afterbegin', addToReiIframe);
    ToReiVideoPause();
};
const ToReiVideoPlay = () => {
    const video = document.querySelector('#js-to-rei-video');
    video.play();
};
const deleteYoutube = () => {
    const element = document.querySelector('#addToReiIframe');
    element.remove();
    ToReiVideoPlay();
};
MicroModal.init({
    onShow: addYoutube,
    onClose: deleteYoutube
});
window.addEventListener('DOMContentLoaded', () => {
    const takahashiVideo = document.querySelector('#js-takahashi-video');
    takahashiVideo.pause();
    takahashiVideo.addEventListener('canplaythrough', () => {
        takahashiVideo.addEventListener('mouseenter', () => {
            takahashiVideo.volume = 0.0;
            takahashiVideo.play();
        });
    });
    takahashiVideo.addEventListener('ended', () => {
        takahashiVideo.load();
        takahashiVideo.pause();
        takahashiVideo.currentTime = 0;
    });
    takahashiVideo.addEventListener('mouseleave', () => {
        takahashiVideo.load();
        takahashiVideo.pause();
        takahashiVideo.currentTime = 0;
    });
    const kanekoVideo = document.querySelector('#js-kaneko-video');
    kanekoVideo.pause();
    kanekoVideo.addEventListener('canplaythrough', () => {
        kanekoVideo.addEventListener('mouseenter', () => {
            kanekoVideo.volume = 0.0;
            kanekoVideo.play();
        });
    });
    kanekoVideo.addEventListener('ended', () => {
        kanekoVideo.load();
        kanekoVideo.pause();
        kanekoVideo.currentTime = 0;
    });
    kanekoVideo.addEventListener('mouseleave', () => {
        kanekoVideo.load();
        kanekoVideo.pause();
        kanekoVideo.currentTime = 0;
    });
});
